import React, { useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-coverflow'
import '../style.css'
import './ImageSlider.css'
import Hero from './Hero'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, EffectCoverflow, A11y, Autoplay } from 'swiper'
import { ArrowButton } from '@truphone/buttons'
import { VideoModal } from '@truphone/modals'
import { ImageWrap } from '@truphone/gatsby-wrappers'

const ImageSlider = ({
  images,
  width,
  height,
  title,
  subtitle,
  slidesToShow = 3,
  snackBox,
  autoplay = true,
  container
}) => {
  const [videoURL, setVideoURL] = useState(0)
  const [play, setPlay] = useState(false)

  function setIsPlaying(i) {
    setVideoURL(i)
    const pageMain =
      (document && document.querySelector('.page-main')) || document.querySelector('#root')
    if (pageMain) {
      pageMain.style.zIndex = 2147483641
      setPlay(true)
    }
  }
  const settings = {
    modules: [Autoplay, EffectCoverflow, Navigation, A11y],
    effect: 'coverflow',
    centeredSlides: true,
    slidesPerView: slidesToShow,
    coverflowEffect: {
      rotate: 0, // Slide rotate in degrees
      stretch: 40, // Stretch space between slides (in px)
      depth: 300, // Depth offset in px (slides translate in Z axis)
      modifier: 1, // Effect multipler
      slideShadows: false // Enables slides shadows
    },
    autoplay: autoplay ? { delay: 5000 } : false,
    speed: 1000,
    loop: true,
    navigation: true,
    breakpoints: {
      768: {
        slidesPerView: slidesToShow
      }
    }
  }

  const templateImages = images.map((image, i) => {
    if (image !== null) {
      return (
        <SwiperSlide key={`slide-${i}`}>
          <div className={`absolute z-1 w-full h-full p-6 md:p-6 lg:p-10 flex flex-col box-border`}>
            {image.topTitle && (
              <h4 className="text-xs leading-5 sm:text-base font-bold uppercase tracking-widest bg-green-100 rounded p-custom px-4 mr-auto">
                {image.topTitle}
              </h4>
            )}
            <h2 className="w-3/4 sm:w-1/2 pr-4 carousel-title text-white mt-4 md:mt-6 xl:mt-10">
              {' '}
              {image.title}
            </h2>
            <div className="mt-auto">
              {image.links &&
                image.links.map((link, i) => {
                  return (
                    <ArrowButton
                      key={`image-slide-cta-${i}`}
                      className="flex text-white hover:text-white carousel-link font-light mt-1 sm:mt-2 xl:mt-4"
                      href={link.href}
                      target={link.ctaOpenNewTab ? '_blank' : ''}
                    >
                      {link.text}
                    </ArrowButton>
                  )
                })}
            </div>
          </div>

          <div className="flex justify-center  ">
            {image.videoModal ? (
              <div className="relative div-hover z-10 rounded-xl swiper-image overflow-hidden">
                <ImageWrap
                  className="img w-full h-full rounded-lg object-cover"
                  {...image.image}
                  loading="eager"
                  style={{ width: width, height: height }}
                />

                <div
                  onClick={() => setIsPlaying(image.videoModal.url)}
                  className="pointer-events-none video-modal cursor-pointer absolute top-0 left-0 text-white w-full h-full flex justify-center items-center"
                >
                  <img width="80" height="80" src="/images/icons/icon-play.svg" alt="play" />
                  {image.playText && (
                    <p
                      className={`text-center font-bold absolute bottom-5 left-1/2 -translate-x-1/2 uppercase text-base tracking-wider`}
                    >
                      {image.playText}
                    </p>
                  )}
                </div>
              </div>
            ) : image.video && image.video.url ? (
              <video
                className="w-full h-full rounded-lg"
                src={image.video.url}
                placeholder={image.image.url}
                autoPlay
                muted
                loop
              />
            ) : (
              <ImageWrap className="w-full h-full rounded-lg" {...image.image} loading="eager" />
            )}
          </div>
        </SwiperSlide>
      )
    }
    return null
  })

  return (
    <>
      {title && <Hero title={title} subtitle={subtitle} snackBox={snackBox} />}
      <div
        className={`overflow-hidden ${container ? 'container px-10' : 'w-full'} ${
          title ? '-mt-44' : ''
        } mx-auto`}
      >
        <Swiper
          onClick={(swiper) => {
            if (swiper.activeIndex > swiper.clickedIndex) {
              swiper.slidePrev()
            } else if (swiper.activeIndex < swiper.clickedIndex) {
              swiper.slideNext()
            } else {
              if (swiper.clickedSlide.querySelector('.video-modal')) {
                swiper.clickedSlide.querySelector('.video-modal').click()
              } else if (swiper.clickedSlide.querySelector('a')) {
                swiper.clickedSlide.querySelector('a').click()
              }
            }
          }}
          {...settings}
        >
          {templateImages}
        </Swiper>
      </div>
      {images && <VideoModal isOpen={play} handleOpen={() => setPlay()} href={videoURL} />}
    </>
  )
}

export default ImageSlider
