import React, { useEffect } from 'react'
import { ArrowButton } from '@truphone/buttons'
import './logos-carousel.css'
import { LinkWrap, ImageWrap } from '@truphone/gatsby-wrappers'

const LogoImages = (props) => {
  return props.logos.map((logo, i) => {
    return (
      <div
        key={`logo-image-${i}`}
        className={`logos-container relative ${
          props.small ? 'h-16' : 'h-32'
        } overflow-hidden mx-auto`}
      >
        <div
          key={`carousel-logo-${i}`}
          className={`infinite-carousel-logos carousel-logos-${i} absolute top-0 left-0 h-full flex flex-nowrap ${
            i !== 0 ? 'mt-4 lg:mt-8' : ''
          }`}
        >
          <ImageWrap {...logo} loading="lazy" />
          <ImageWrap {...logo} loading="lazy" style={{ left: `${logo.width}px` }} />
        </div>
      </div>
    )
  })
}

const LogoImagesWrap = (props) => {
  const seeAllCta = props.seeAll && props.seeAll.length > 0 && props.seeAll[0]
  return props.hover ? (
    <LinkWrap href={seeAllCta.href} className="w-full relative h-full block logos-hover">
      <LogoImages {...props} />
      <span className="cta absolute inline-flex w-full justify-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-medium text-blue-100 text-xl">
        <ArrowButton href={seeAllCta.href}>{seeAllCta.text}</ArrowButton>
      </span>
    </LinkWrap>
  ) : (
    <>
      <LogoImages {...props} />
      {seeAllCta && (
        <ArrowButton
          className="text-xl font-semibold text-blue-100 inline-flex mt-8 lg:mt-20"
          href={seeAllCta.href}
          target={seeAllCta.ctaOpenNewTab ? '_blank' : ''}
        >
          {seeAllCta.text}
        </ArrowButton>
      )}
    </>
  )
}

export default function LogosCarousel(props) {
  useEffect(() => {
    const resizeCarousel = () => {
      setTimeout(() => {
        var infiniteCarousels = document.querySelectorAll('.infinite-carousel-logos')
        if (infiniteCarousels && infiniteCarousels.length) {
          infiniteCarousels.forEach((logo, i) => {
            document
              .querySelector('.carousel-logos-' + i)
              .style.setProperty('--carousel-width', props.logos[i].width + 'px')
          })
        }
      }, 500)
    }
    resizeCarousel()
    window.addEventListener('resize', resizeCarousel)
    return () => {
      window.removeEventListener('resize', resizeCarousel)
    }
  }, [])

  return (
    <div
      className={`py-8 text-center 
      ${props.small ? '' : 'lg:pt-20 lg:pb-28'}
      ${props.backgroundColor === 'gray' ? 'bg-gray-100' : ''}`}
    >
      {props.title && (
        <h3 className="max-w-lg px-5 mx-auto mb-8 md:mb-14 text-2xl md:text-4xl md:leading-snug tracking-tight">
          {props.title}
        </h3>
      )}

      <LogoImagesWrap {...props} />
    </div>
  )
}
