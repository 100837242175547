import React from 'react'
import '../style.css'
import TabsText from './TabsText'
import { SnackBox } from '@truphone/core-components'
import { uppercaseString } from '@truphone/utils'

export default function Hero(props) {
  return (
    <>
      <div
        className={`py-32 ${props.inPageLinks ? 'pb-8 lg:pb-0' : 'pb-32'} 
         text-white relative z-0 bg-gradient-after ${
           props.background ? 'bg-cover bg-center' : 'pb-44 lg:pb-48 bg-gradient-home'
         }`}
        style={{ backgroundImage: `${props.background ? `url(${props.background.url})` : ''}` }}
      >
        {props.snackBox && <SnackBox {...props.snackBox} />}
        <div className="max-w-4xl px-4 mx-auto text-center z-1 relative">
          <h1 className="heron leading-none text-7xl-resp">{uppercaseString(props.title)}</h1>
          <h3 className="my-4 font-light text-base md:text-2xl tracking-wide">{props.subtitle}</h3>
        </div>
        <div className="container px-6 mx-auto text-center">
          {props.inPageLinks && <TabsText tabItems={props.inPageLinks} />}
        </div>
      </div>
    </>
  )
}
