import React from 'react'
import { uppercaseString } from '@truphone/utils'
import { Arrow } from '@truphone/icons'
import '../style.css'

export default function TabsText(props) {
  return (
    <div className="text-white relative z-1 mt-10 lg:mt-20">
      <div className="flex flex-col lg:items-center lg:flex-row lg:inline-flex mx-auto">
        {props.tabItems &&
          props.tabItems.map((tab, i) => {
            return (
              <a
                key={`tab-cta-${i}`}
                href={tab.href}
                className="flex text-left lg:text-center lg:hover:bg-white lg:hover:bg-opacity-40 lg:min-w--250 py-5 lg:px-5 xl:px-10 lg:py-3 text-sm lg:text-base font-bold tracking-xwidest lg:rounded-tr-xl lg:rounded-tl-xl"
              >
                {uppercaseString(tab.text)}
                <Arrow className="lg:hidden transform rotate-90 w-4 h-4 ml-4" />
              </a>
            )
          })}
      </div>
    </div>
  )
}
