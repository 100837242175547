import React, { useState } from 'react'
import { ArrowButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import '../style.css'

export default function Tabs(props) {
  const [selected, setSelected] = useState(0)

  return (
    <div className="bg-gradient-home">
      <div className="pt-10 pb-12 lg:pt-20 lg:pb-28 text-white text-center ">
        <div className="w-full mx-auto overflow-x-auto pb-5 px-4 mb-6 lg:mb-20">
          <div className="flex-nowrap inline-flex justify-start xl:justify-center border border-white rounded-xl">
            {props.tabs &&
              props.tabs.map((tab, i) => {
                return (
                  <button
                    key={`tab-cta-${i}`}
                    onClick={() => setSelected(i)}
                    className={`tabs-border relative flex-grow lg:min-w--250 px-4 py-2 lg:px-10 lg:py-3 text-sm lg:text-base font-bold tracking-xwidest text-center rounded-xl uppercase ${
                      selected === i ? 'is-active bg-blue-900' : ''
                    }`}
                  >
                    {tab.title}
                  </button>
                )
              })}
          </div>
        </div>
        {props.tabs &&
          props.tabs.map((tab, i) => {
            return (
              <div
                key={`tab-component${i}`}
                className={`container mx-auto text-center z-1 relative px-4 ${
                  selected === i ? 'flex flex-wrap justify-around' : 'hidden'
                }`}
              >
                {tab.content &&
                  tab.content.map((el, i) => {
                    return (
                      <div key={`tab-content-${i}`} className="w-full sm:w-1/3">
                        <div className="flex flex-col h-full p-3 sm:p-5">
                          {el.image && <ImageWrap className="mx-auto" {...el.image} />}

                          <div className="mt-4">
                            <h3 className="font-light text-xl lg:text-3xl">{el.title}</h3>
                            <h3 className="font-light lg:mt-1 text-xl lg:text-3xl">
                              {el.subtitle}
                            </h3>
                          </div>
                          <div
                            className="mt-5 mb-2 text-sm lg:text-base"
                            dangerouslySetInnerHTML={{ __html: el.content }}
                          />

                          {el.ctaLink && (
                            <ArrowButton
                              className="text-sm lg:text-lg font-semibold inline-flex mx-auto mt-auto p-3"
                              href={el.ctaLink}
                              target={el.ctaOpenTab ? '_blank' : null}
                            >
                              {el.ctaText}
                            </ArrowButton>
                          )}
                        </div>
                      </div>
                    )
                  })}
              </div>
            )
          })}
      </div>
    </div>
  )
}
